import { DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { PriceCurrencyPipe } from '../../../pipes/price-currency.pipe';
import { SearchResultsResponseTour } from '../../../services/search/websocket-tours.model';
import { SearchTourLabelCombinedComponent } from '../../tour/label-combi/search-tour-label-combined.component';
import { SearchTourLabelGdsComponent } from '../../tour/label-gds/search-tour-label-gds.component';
import { SearchTourLabelSplitRoomsComponent } from '../../tour/label-split-rooms/search-tour-label-split-rooms.component';

@Component({
  selector: 'app-search-tour-calendar-tour',
  templateUrl: './search-tour-calendar-tour.component.html',
  styleUrl: './search-tour-calendar-tour.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    SearchTourLabelCombinedComponent,
    SearchTourLabelGdsComponent,
    SearchTourLabelSplitRoomsComponent,
    DecimalPipe,
    PriceCurrencyPipe,
  ],
})
export class SearchTourCalendarTourComponent {
  tour = input.required<SearchResultsResponseTour>();
  selected = input<boolean>(true);

  airlines = computed<string>(() => {
    if (this.tour().freights.airlines) {
      return this.tour()
        .freights.airlines.map(v => v.code)
        .join('+');
    }

    return '';
  });

  operatorCode = computed<string>(() => {
    return this.tour().operator.code;
  });

  mealName = computed<string>(() => {
    return this.tour().meal.code || this.tour().meal.name;
  });

  tourNights = computed<string>(() => {
    const nights = this.tour().nights;
    let text = nights.toString();
    if (this.tour().nightsOnWay > 0) {
      text += `+${this.tour().nightsOnWay}`;
    }

    return text;
  });

  isSplit = computed<boolean>(() => {
    return this.tour().rooms?.length > 1;
  });

  room = computed<string>(() => {
    const tour = this.tour();
    return tour.rooms.map(room => `${room.name} / ${room.accommodation}`).join(' + ');
  });
}
