<div class="container">
  @if (showLoader()) {
    <div class="container-loader">
      <div class="skeleton">
        <div class="skeleton-logo"></div>
        <div class="skeleton-date-time"></div>
        <div class="skeleton-info"></div>
      </div>
      <div class="skeleton">
        <div class="skeleton-logo"></div>
        <div class="skeleton-date-time"></div>
        <div class="skeleton-info"></div>
      </div>
    </div>
  }

  @if (showNoResults()) {
    <div class="container-no-results">
      Перелеты не найдены. Перейдите на сайт ТО для получения информации
    </div>
  }

  @if (showErrors()) {
    <div class="container-errors">
      Ошибка при получение авиаперелетов
    </div>
  }

  @if (showVariants()) {
    <div class="container-variants">
      @if (tour().freights?.seats) {
        <app-tour-flight-seats [seats]="tour().freights.seats" />
      }

      <div class="variant">
        <app-search-result-map-info-window-flight-direction [direction]="variant().forward" />
        <app-search-result-map-info-window-flight-direction [direction]="variant().backward" />
      </div>
    </div>
  }
</div>
