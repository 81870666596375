import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToursSearchConnectionService } from './tours-search-connection.service';
import { ToursStorageService } from './tours-storage.service';
import {
  InitSearchRequest,
  SearchConfig,
  SearchResult,
  SearchResultsResponse,
  SearchResultsResponseResultType,
} from './websocket-tours.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketToursSearchService {
  private mainSearchId: string;

  constructor(
    private readonly webSocketService: ToursSearchConnectionService,
    private readonly toursStorageService: ToursStorageService,
  ) {}

  static generateId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  searchTours(initSearchRequest: InitSearchRequest, config?: SearchConfig): Observable<SearchResult> {
    const searchRequest = initSearchRequest;
    const isMainSearch = config?.isMainSearch !== undefined ? config.isMainSearch : true;
    const addToursInMainResult =
      config?.addToursInMainResult !== undefined ? config.addToursInMainResult : true;

    if (isMainSearch) {
      this.mainSearchId = initSearchRequest.id;
    } else {
      const mainSearchId = this.mainSearchId ? this.mainSearchId : WebsocketToursSearchService.generateId();
      searchRequest.id = mainSearchId + '---' + initSearchRequest.id;
    }

    return this.processSearch(searchRequest, addToursInMainResult);
  }

  private processSearch(
    searchRequest: InitSearchRequest,
    addToursInMainResult: boolean,
  ): Observable<SearchResult> {
    return new Observable(observer => {
      const subscription = this.webSocketService
        .createMultiplexedStream(searchRequest.id, searchRequest)
        .subscribe({
          next: (message: SearchResultsResponse) => {
            const requestId = searchRequest.id;
            if (message.result) {
              if (message.result.type === SearchResultsResponseResultType.searchResults) {
                const tours = message.result?.data?.tours || [];
                if (tours.length > 0) {
                  this.toursStorageService.saveSearchResults(requestId, tours);
                  if (addToursInMainResult) {
                    this.toursStorageService.saveSearchResults(this.mainSearchId, tours);
                  }
                }

                const resultTours = this.toursStorageService.getSearchResults(requestId);
                const resultToursCount = resultTours.length;
                observer.next({
                  tours: resultTours,
                  searchIsDone: false,
                  hasTours: resultToursCount > 0,
                  totalTours: this.toursStorageService.getToursCount(requestId),
                });
              } else if (message.result.type === SearchResultsResponseResultType.searchCompleted) {
                const resultTours = this.toursStorageService.getSearchResults(requestId);
                const resultToursCount = resultTours.length;
                observer.next({
                  tours: resultTours,
                  searchIsDone: true,
                  hasTours: resultToursCount > 0,
                  totalTours: this.toursStorageService.getToursCount(requestId),
                });
                observer.complete();
              }
            }

            if (message.error) {
              observer.error(message.error.message);
            }
          },
          error: error => observer.error(error),
          complete: () => observer.complete(),
        });

      return () => subscription.unsubscribe();
    });
  }
}
