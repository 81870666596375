import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchResultsResponseTour } from './websocket-tours.model';

@Injectable({
  providedIn: 'root',
})
export class ToursStorageService {
  private tourResults: Map<string, BehaviorSubject<SearchResultsResponseTour[]>> = new Map<
    string,
    BehaviorSubject<SearchResultsResponseTour[]>
  >();
  private tourResultsCount = new Map<string, number>();

  flushSearchResults(requestId: string): void {
    if (!this.tourResults.has(requestId)) {
      return;
    }
    this.tourResults.get(requestId).complete();
    this.tourResults.delete(requestId);
  }

  flushAllSearchResults(): void {
    this.tourResults.forEach((result, key) => {
      if (key === 'main-search') {
        result.next([]);
      } else {
        result.complete();
        this.tourResults.delete(key);
      }
    });

    this.tourResultsCount = new Map<string, number>();
  }

  saveSearchResults(requestId: string, tours: SearchResultsResponseTour[]): void {
    tours.forEach(tour => {
      const now = new Date();
      now.setMinutes(now.getMinutes() + 30);

      tour.expiredAt = now;
    });

    this.updateToursCount(requestId, tours.length);
    if (!this.tourResults.has(requestId)) {
      this.tourResults.set(requestId, new BehaviorSubject<SearchResultsResponseTour[]>(tours));
    } else {
      const currentToursSub = this.tourResults.get(requestId);
      const allTours = currentToursSub.value.concat(tours);

      currentToursSub.next(allTours);
    }
  }

  getSearchResults(requestId: string): SearchResultsResponseTour[] {
    if (!this.tourResults.has(requestId)) {
      this.tourResults.set(requestId, new BehaviorSubject<SearchResultsResponseTour[]>([]));
    }

    return this.tourResults.get(requestId).value;
  }

  getSearchResults$(requestId: string): Observable<SearchResultsResponseTour[]> {
    if (!this.tourResults.has(requestId)) {
      this.tourResults.set(requestId, new BehaviorSubject<SearchResultsResponseTour[]>([]));
    }

    return this.tourResults.get(requestId).asObservable();
  }

  getToursCount(requestId: string): number {
    return this.tourResultsCount.get(requestId) || 0;
  }

  private updateToursCount(requestId: string, newToursCount: number): void {
    const currentToursCount = this.tourResultsCount.get(requestId) || 0;

    this.tourResultsCount.set(requestId, currentToursCount + newToursCount);
  }
}
