import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum AlertLabelType {
  info = 'info',
  warning = 'warning',
  danger = 'danger',
}

@Component({
  selector: 'app-alert-label',
  templateUrl: './alert-label.component.html',
  styleUrls: ['./alert-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
  ],
})
export class AlertLabelComponent {
  @Input() type: AlertLabelType = AlertLabelType.info;
}
