import { Pipe, PipeTransform } from '@angular/core';
import { SearchResultsResponseSeatAvailable } from '../../../../../../../../../../../../../search/services/search/websocket-tours.model';

@Pipe({
  name: 'seatTourFlightSeatsAvailable',
  standalone: true,
})
export class TourFlightSeatsAvailablePipe implements PipeTransform {
  transform(available: SearchResultsResponseSeatAvailable): string {
    switch (available) {
      case SearchResultsResponseSeatAvailable.yes:
        return 'много';
      case SearchResultsResponseSeatAvailable.no:
        return 'нет';
      case SearchResultsResponseSeatAvailable.few:
        return 'мало';
      case SearchResultsResponseSeatAvailable.request:
        return 'под запрос';
      default:
        return available;
    }
  }
}
