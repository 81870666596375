/**
 *
 * @param {number} value
 * @param {string} words формы через запятую 1,4,8 'день,дня,дней'
 */
export function pluralize(value: number, words: string[]): string {
    let mod = value % 100;
    if (mod > 4 && mod < 20) {
        return words[2];
    }
    mod = mod % 10;
    if (mod === 0) {
        return words[2];
    }
    if (mod === 1) {
        return words[0];
    }
    if (mod < 5) {
        return words[1];
    }
    return words[2];
}
