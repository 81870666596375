import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SearchResultsResponseFreightsSeats } from '../../../../../../../../../../../../search/services/search/websocket-tours.model';
import { TourFlightSeatsAvailablePipe } from './pipe/tour-flight-seats-available.pipe';

@Component({
  selector: 'app-tour-flight-seats',
  templateUrl: './tour-flight-seats.component.html',
  styleUrl: './tour-flight-seats.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TourFlightSeatsAvailablePipe],
})
export class TourFlightSeatsComponent {
  seats = input.required<SearchResultsResponseFreightsSeats>();
}
