<div class="title-container" #allItems [style.margin-bottom.px]="visibleCount === 0 ? '0' : '12'">
  <div class="title" (click)="openDropdown()">
    <div class="name">
      <div>{{ label }}</div>
    </div>
    <div class="btn-more-items">
      @if (selectedCount()) {
        <span class="count">{{ selectedCount() }}</span>
      }
      @if (selectedCount()) {
        <span class="remove-all" (click)="removeSelected($event)">
          <img src="assets/icons/favorite-hotels/close.svg">
        </span>
      }
      <img src="assets/icons/favorite-hotels/chevron-right.svg">
    </div>
  </div>
</div>

<div class="items">
  @for (item of items | slice:0:visibleCount; track item.id) {
    <div class="checkbox">
      <label>
        <input type="checkbox"
          [(ngModel)]="item.selected"
          (change)="onCheckboxChange()">
        {{ item.name }}
      </label>
    </div>
  }

  @if (visibleCount > 0) {
    <div class="more">
      <span class="more-label" (click)="openDropdown()">Еще варианты</span>
    </div>
  }
</div>
