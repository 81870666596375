import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlightVariant } from '@api-clients/api-client';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';

@Injectable()
export class SearchTourFlightsService {
  private flightRequestCache: { [key: string]: Observable<FlightVariant[]> } = {};

  constructor(private readonly http: HttpClient) {}

  load$(tourId: string): Observable<FlightVariant[]> {
    if (this.flightRequestCache[tourId]) {
      return this.flightRequestCache[tourId];
    }

    const params = new HttpParams().set('tourId', tourId);

    this.flightRequestCache[tourId] = this.http
      .get<FlightVariant[]>(`${AppConfig.apiUrl}/tour/view-flights`, { params })
      .pipe(
        shareReplay(1),
        tap({
          complete: () => {
            delete this.flightRequestCache[tourId];
          },
        }),
        apiResponsePipe,
      );

    return this.flightRequestCache[tourId];
  }
}
