import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ManagerSearchTourCalendarRequest, ManagerTourCalendar } from '@api-clients/api-client';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';

@Injectable({ providedIn: 'root' })
export class SearchTourCalendarService {
  private pendingRequests = new Map<string, Subject<ManagerTourCalendar>>();
  private cacheTTL = 600000;

  constructor(private http: HttpClient) {}

  getDefaultApiRequest(tourId: string): ManagerSearchTourCalendarRequest {
    return {
      tourId,
      mealIds: [],
      operatorIds: [],
      airlineIds: [],
      dateFromShift: 0,
    };
  }

  getCalendar(request: ManagerSearchTourCalendarRequest): Observable<ManagerTourCalendar> {
    request.expands = ['tours'];

    const url = `${AppConfig.apiUrl}/tour/calendar`;
    const key = JSON.stringify(request);
    const cachedResponse = this.getCachedResponse(key);

    if (cachedResponse) {
      return of(cachedResponse);
    }

    if (this.pendingRequests.has(key)) {
      return this.pendingRequests.get(key).asObservable();
    }

    const subject = new Subject<ManagerTourCalendar>();
    this.pendingRequests.set(key, subject);

    this.http
      .post<ManagerTourCalendar>(url, request)
      .pipe(
        catchError(error => {
          subject.error(error);
          this.pendingRequests.delete(key);
          return of(null);
        }),
        apiResponsePipe,
        tap(response => {
          this.setCachedResponse(key, response);
          subject.next(response);
          subject.complete();
        }),
      )
      .subscribe();

    return subject.asObservable();
  }

  private getCachedResponse(key: string): ManagerTourCalendar | null {
    const cached = localStorage.getItem(key);
    if (cached) {
      const cachedData = JSON.parse(cached);
      if (new Date().getTime() - cachedData.timestamp < this.cacheTTL) {
        return cachedData.response;
      } else {
        localStorage.removeItem(key);
      }
    }
    return null;
  }

  private setCachedResponse(key: string, response: any): void {
    const cachedData = {
      response,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(key, JSON.stringify(cachedData));
  }
}
