<div class="tour-information-container" [class.selected]="selected()">
  <div class="tour-information">
    <div class="tour-information-main">
      <span class="tour-depart-date">{{ tour().date | date:'dd.MM' }} на {{ tourNights() }} н.</span>
      <span class="tour-operator">{{ operatorCode() }}</span>
      @if (isSplit()) {
        <app-search-tour-label-split-rooms />
      }
    </div>

    <div class="tour-information-ext">
      @if (airlines()) {
        <span class="tour-airlines">{{ airlines() }}, </span>
      }
      <span class="tour-meal">{{ mealName() }}</span>
      @if (tour().isGDS) {
        <app-search-tour-label-gds />
      }
      @if (tour().isCombined) {
        <app-search-tour-label-combined />
      }
    </div>
    <div class="tour-information-ext">
      <span class="tour-rooms">{{ room() }}</span>
    </div>
    @if (tour().departureTimes) {
      <div class="tour-available-depart-times">
        <img src="assets/icons/search/flights.svg"> {{ tour().departureTimes.join(', ') }}
      </div>
    }
  </div>
  <div class="tour-price-value">
    {{ tour().brandPrice.value | number: '1.0-0' }} {{ tour().brandPrice.currency | priceCurrency }}
  </div>
</div>
