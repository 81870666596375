<div class="seats">
  <div class="available">
    <h4 class="seats-direction">Места туда</h4>
    <div class="seats-type">
      @if (seats().economy) {
        <div class="seats-economy">Эконом: {{ seats().economy.forward | seatTourFlightSeatsAvailable }}</div>
      }
      @if (seats().business) {
        <div class="seats-business">Бизнес: {{ seats().business.forward | seatTourFlightSeatsAvailable }}</div>
      }
    </div>
  </div>
  <div class="available">
    <h4 class="seats-direction">Места обратно</h4>
    <div class="seats-type">
      @if (seats().economy) {
        <div class="seats-economy">Эконом: {{ seats().economy.backward | seatTourFlightSeatsAvailable }}</div>
      }
      @if (seats().business) {
        <div class="seats-business">Бизнес: {{ seats().economy.backward | seatTourFlightSeatsAvailable }}</div>
      }
    </div>
  </div>
</div>
