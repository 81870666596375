import { Pipe, PipeTransform } from '@angular/core';
import { pluralize } from './pluralize';

@Pipe({
  name: 'pluralize',
  standalone: true,
})
export class PluralizePipe implements PipeTransform {
  /**
   *
   * @param {number} value
   * @param {string} words формы через запятую 1,4,8 'день,дня,дней'
   */
  transform(value: number, words: string): string {
    const word = pluralize(value, words.split(','));
    return `${value} ${word}`;
  }
}
