<div class="container" [class.two-column]="items.length > 10">
  <div class="items">
    @for (item of items; track item.id) {
      <div class="checkbox">
        <label>
          <input type="checkbox"
                 [(ngModel)]="item.selected"
                 (change)="onCheckboxChange()">
          {{ item.name }}
        </label>
      </div>
    }
  </div>
</div>
