import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceCurrency',
  standalone: true,
})
export class PriceCurrencyPipe implements PipeTransform {
  private currencyLabels = {
    kzt: '₸',
    usd: '$',
    eur: '€',
    uah: '₴',
  };

  transform(value: string): string {
    value = value.toLocaleLowerCase();

    return this.currencyLabels[value] || value;
  }
}
