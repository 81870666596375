import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-search-tour-label-split-rooms',
  template: `<span class="is-split">Сплит</span>`,
  styles: `
    .is-split {
      border: 1px solid #21304e;
      font-size: 9px;
      border-radius: 8px;
      padding: 0 4px;
      color: #21304e;
      font-weight: 600;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchTourLabelSplitRoomsComponent {}
