import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-search-tour-label-combined',
  template: `<span class="label-combined">комби</span>`,
  styles: `
    .label-combined {
      text-transform: uppercase;
      font-size: 8px;
      border-radius: 8px;
      padding: 2px 4px;
      background-color: #ff7a00;
      color: #fff;
      font-weight: 600;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchTourLabelCombinedComponent {}
