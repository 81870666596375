import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  input,
  OnChanges,
  OnInit,
  signal,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlightVariant } from '@api-clients/api-client';
import { Subject, switchMap } from 'rxjs';
import { SearchTourFlightsService } from '../../../../../../../../../../../search/services/search-tour-flights.service';
import { SearchResultsResponseTour } from '../../../../../../../../../../../search/services/search/websocket-tours.model';
import { SearchResultMapInfoWindowFlightDirectionComponent } from './direction/search-result-map-info-window-flight-direction.component';
import { TourFlightSeatsComponent } from './seats/tour-flight-seats.component';

@Component({
  selector: 'app-search-result-map-info-window-tour-flights',
  templateUrl: './search-result-map-info-window-tour-flights.component.html',
  styleUrl: './search-result-map-info-window-tour-flights.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TourFlightSeatsComponent, SearchResultMapInfoWindowFlightDirectionComponent],
})
export class SearchResultMapInfoWindowTourFlightsComponent implements OnInit, OnChanges {
  tour = input.required<SearchResultsResponseTour>();

  showLoader = signal<boolean>(true);
  showVariants = signal<boolean>(false);
  showNoResults = signal<boolean>(false);
  showErrors = signal<boolean>(false);
  variant = signal<FlightVariant>(undefined);

  private destroyRef = inject(DestroyRef);
  private tourId$ = new Subject<string>();

  constructor(
    private readonly flightService: SearchTourFlightsService,
    private readonly cdRed: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.tourId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(tourId => {
          this.showLoader.set(true);
          this.showVariants.set(false);
          return this.flightService.load$(tourId).pipe(takeUntilDestroyed(this.destroyRef));
        }),
      )
      .subscribe({
        next: variants => {
          this.showLoader.set(false);
          if (variants.length) {
            this.variant.set(variants[0]);
            this.showVariants.set(true);
          } else {
            this.variant.set(undefined);
            this.showNoResults.set(true);
          }

          this.cdRed.detectChanges();
        },
        error: () => {
          this.variant.set(undefined);
          this.showLoader.set(false);
          this.showErrors.set(true);
        },
      });

    this.loadFlights();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tour?.currentValue?.id !== changes.tour?.previousValue?.id) {
      this.loadFlights();
    }
  }

  private loadFlights(): void {
    this.tourId$.next(this.tour().id);
  }
}
