import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, delay, retryWhen, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { AuthenticationService } from '../../../../core/services';

@Injectable({
  providedIn: 'root',
})
export class ToursSearchConnectionService {
  private socket$: WebSocketSubject<any>;
  private url = 'wss://ws.ht.kz/ws/v2/';
  private reconnectInterval = 2000;

  private connectionStatusSubject = new Subject<string>();

  constructor(private authService: AuthenticationService) {
    this.connect();
  }

  private connect(): void {
    const employeeToken = this.authService.employeeToken;
    const config: WebSocketSubjectConfig<any> = {
      url: this.url,
    };
    if (employeeToken) {
      config.protocol = ['jsonrpc', `token.employee.${employeeToken}`];
    }
    this.socket$ = webSocket(config);

    this.socket$
      .pipe(
        retryWhen(errors =>
          errors.pipe(
            tap(err => this.connectionStatusSubject.next('disconnected')),
            delay(this.reconnectInterval),
            tap(() => this.connectionStatusSubject.next('reconnecting')),
          ),
        ),
        catchError(error => {
          this.connectionStatusSubject.next('failed');
          return throwError(error);
        }),
      )
      .subscribe({
        next: () => {
          this.connectionStatusSubject.next('connected');
        },
        error: error => {
          console.error('WebSocket error:', error);
        },
        complete: () => {
          console.info('WebSocket complete');
        },
      });
  }

  sendMessage(message: any): void {
    this.socket$.next(message);
  }

  createMultiplexedStream(id: string, message: any): Observable<any> {
    return new Observable(observer => {
      const subMessage = message;
      const unsubMessage = { id, type: 'unsubscribe' };

      this.socket$
        .multiplex(
          () => subMessage,
          () => unsubMessage,
          message => message.id === id,
        )
        .subscribe(observer);

      return () => {
        this.socket$.next(unsubMessage);
      };
    });
  }

  getMessages(): Observable<any> {
    return this.socket$.asObservable();
  }

  getConnectionStatus(): Observable<string> {
    return this.connectionStatusSubject.asObservable();
  }
}
