import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
} from '@angular/core';

const HOST_SELECTOR_CLASS_PAIR: { attribute: string; mdcClasses: string[] }[] = [
  {
    attribute: 'brx-button',
    mdcClasses: ['brx-button'],
  },
];

@Component({
  selector: 'button[brx-button]',
  templateUrl: './brx-button.component.html',
  styleUrl: './brx-button.component.scss',
  exportAs: 'brx-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    '[attr.disabled]': '_getDisabledAttribute()',
    '[class]': 'color ? "brx-" + color : ""',
  },
})
export class BrxButtonComponent {
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this.#disabled;
  }

  set disabled(value: any) {
    this.#disabled = value;
  }

  #disabled = false;

  @Input() color?: string | null;

  #elementRef = inject(ElementRef);

  constructor() {
    const element = this.#elementRef.nativeElement;
    const classList = (element as HTMLElement).classList;

    for (const { attribute, mdcClasses } of HOST_SELECTOR_CLASS_PAIR) {
      if (element.hasAttribute(attribute)) {
        classList.add(...mdcClasses);
      }
    }
  }

  protected _getDisabledAttribute() {
    return !this.disabled ? null : true;
  }

  focus(): void {
    this.#elementRef.nativeElement.focus();
  }
}
