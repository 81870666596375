import { Directive, ElementRef, HostListener, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appDraggable]',
})
export class DraggableDirective implements OnInit {
  @Input() dragHandle: HTMLElement | null = null;

  private isDragging = false;
  private initialX = 0;
  private initialY = 0;
  private startX = 0;
  private startY = 0;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.renderer.setStyle(this.el.nativeElement, 'will-change', 'transform');
  }

  ngOnInit(): void {
    const dragElement = this.dragHandle || this.el.nativeElement;
    this.renderer.listen(dragElement, 'mousedown', this.onMouseDown.bind(this));
  }

  onMouseDown(event: MouseEvent): void {
    event.preventDefault();
    this.isDragging = true;
    this.initialX = event.clientX - this.startX;
    this.initialY = event.clientY - this.startY;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (!this.isDragging) {
      return;
    }
    event.preventDefault();

    const moveX = event.clientX - this.initialX;
    const moveY = event.clientY - this.initialY;

    this.startX = moveX;
    this.startY = moveY;

    this.renderer.setStyle(this.el.nativeElement, 'transform', `translate(${moveX}px, ${moveY}px)`);
  }

  @HostListener('document:mouseup')
  onMouseUp(): void {
    this.isDragging = false;
  }
}
