import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-line-progress',
  template: `
    <div class="progress-line progress-line-visible">
      <div class="progress" [style.animation-duration]="animationDuration"></div>
    </div>
  `,
  styleUrls: ['./line-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LineProgressComponent {
  @Input() animationDuration = '10s';
}
