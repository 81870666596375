<div class="direction">
  <div class="airline-logo">
    @if (direction().segments[0].hasAirlineLogo) {
      <img class="logo" [attr.src]="airlineLogo" [matTooltip]="direction().segments[0].airline">
    } @else {
      <img class="logo" src="assets/icons/search/flights-no-logo.svg">
    }
  </div>
  <div class="airport">{{ departureAirport }}</div>
  <div class="date-time">{{ departureDate | date:'dd.MM HH:mm' }}</div>
  <div class="line">
    <div class="transfers">{{ transferLabel }}</div>
    <div class="hr"></div>
  </div>
  <div class="date-time">{{ arrivalDate | date:'dd.MM HH:mm' }}</div>
  <div class="airport">{{ arrivalAirport }}</div>
  <div class="airline-code">{{ direction().segments[0].number }}</div>
</div>
