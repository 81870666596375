import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ManagerSearchTourCalendarRequest, ManagerTourCalendar } from '@api-clients/api-client';
import { ManagerTourCalendarItem } from '@api-clients/api-client/models/manager-tour-calendar-item';
import { Subject, switchMap } from 'rxjs';
import { PluralizePipe } from '../../../../helpers/pipes/plural/pluralize.pipe';
import { DraggableDirective } from '../../../../shared/directives/draggable.directive';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../ui-components/alert-label/alert-label.component';
import { BrxButtonComponent } from '../../../../ui-components/hermes/button/brx-button.component';
import { LineProgressComponent } from '../../../../ui-components/line-progress/line-progress.component';
import {
  CheckboxItem,
  SearchFormCheckboxListComponent,
} from '../../../deals/modules/deal-view/modules/search-tours/components/search-form/checkbox-list/search-form-checkbox-list.component';
import { SearchResultMapInfoWindowTourFlightsComponent } from '../../../deals/modules/deal-view/modules/search-tours/components/search-result/components/map/info-window/flights/search-result-map-info-window-tour-flights.component';
import { PriceCurrencyPipe } from '../../pipes/price-currency.pipe';
import { SearchTourCalendarService } from '../../services/search-tour-calendar.service';
import { WebsocketToursSearchService } from '../../services/search/websocket-tours-search.service';
import {
  InitSearchRequest,
  InitSearchRequestMethod,
  InitSearchRequestOptionsGroupResult,
  InitSearchRequestType,
  SearchResultsResponseTour,
} from '../../services/search/websocket-tours.model';
import { SearchTourCalendarTourComponent } from './tour/search-tour-calendar-tour.component';

type TableCell = { [key: number]: { [key: number]: ManagerTourCalendarItem } };

@Component({
  selector: 'app-search-tour-calendar',
  templateUrl: './search-tour-calendar.component.html',
  styleUrl: './search-tour-calendar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    PluralizePipe,
    DecimalPipe,
    PriceCurrencyPipe,
    NgClass,
    LineProgressComponent,
    AlertLabelComponent,
    ReactiveFormsModule,
    SearchFormCheckboxListComponent,
    DraggableDirective,
    BrxButtonComponent,
    SearchTourCalendarTourComponent,
    SearchResultMapInfoWindowTourFlightsComponent,
  ],
})
export class SearchTourCalendarComponent implements OnInit {
  @Input() tourId: string;
  @Input() hotelName: string;
  @Input() initCreateRequest: ManagerSearchTourCalendarRequest;
  @Input() notGDS: boolean;

  filterForm = this.fb.group({
    mealIds: new FormControl<number[]>([]),
    operatorIds: new FormControl<number[]>([]),
    airlineIds: new FormControl<string[]>([]),
  });

  mealVariants: CheckboxItem[] = [];
  operatorVariants: CheckboxItem[] = [];
  airlineVariants: CheckboxItem[] = [];

  closed = output<void>();
  selectedTour = output<string>();

  dates: string[] = [];
  nights: number[] = [];

  tableData: TableCell = {};
  calendarCreateInProgress = true;

  highlightedDate: string | null = null;
  highlightedNight: number | null = null;

  showUpdateCalendarProgress = false;
  showError = false;
  errorTypeLabel = AlertLabelType.warning;

  viewedTour = signal<SearchResultsResponseTour>(undefined);
  toursInCell = signal<SearchResultsResponseTour[]>([]);

  selectedNights = signal<number>(0);
  selectedDate = signal<string>('');

  showSearchToursLoader = signal<boolean>(false);

  private calendarTours: SearchResultsResponseTour[] = [];

  private calendarShiftValue = 0;
  private readonly SHIFT_VALUE = 8;

  private requestSub = new Subject<ManagerSearchTourCalendarRequest>();
  private requestSub$ = this.requestSub.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdRef: ChangeDetectorRef,
    private readonly tourCalendarService: SearchTourCalendarService,
    private readonly searchToursService: WebsocketToursSearchService,
  ) {}

  ngOnInit() {
    this.populateFilters();

    this.requestSub$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(request => {
          this.showError = false;
          if (!this.calendarCreateInProgress) {
            this.calendarCreateInProgress = true;
          }
          this.cdRef.detectChanges();

          return this.tourCalendarService.getCalendar(request);
        }),
      )
      .subscribe({
        next: calendar => {
          this.calendarCreateInProgress = false;
          if (calendar.success) {
            this.createCalendar(calendar, true);

            this.calendarTours = calendar.tours.map(tour => tour as any);
            const currentCellTour = calendar.items.find(tour => tour.isCurrentTour);
            if (currentCellTour) {
              const currentTour = this.calendarTours.find(tour => currentCellTour.tourId === tour.id);
              if (currentTour) {
                this.viewedTour.set(currentTour);

                this.selectedNights.set(currentTour.nights);
                this.selectedDate.set(currentTour.date);

                this.updateToursInCell(currentTour.nights, currentTour.date);
              }
            }
          } else {
            this.showError = true;
          }
          this.cdRef.detectChanges();
        },
        error: error => {
          console.error(error);

          this.showError = true;
          this.cdRef.detectChanges();
        },
      });

    const initRequest: ManagerSearchTourCalendarRequest = this.initCreateRequest
      ? this.initCreateRequest
      : this.getCreateApiRequest();
    this.requestSub.next(initRequest);
  }

  nextCalendarDate(): void {
    this.calendarShiftValue += this.SHIFT_VALUE;

    const request = this.getCreateApiRequest();
    this.requestSub.next(request);
  }

  prevCalendarDate(): void {
    this.calendarShiftValue -= this.SHIFT_VALUE;

    const request = this.getCreateApiRequest();
    this.requestSub.next(request);
  }

  highlightCell(date: string, night: number) {
    this.highlightedDate = date;
    this.highlightedNight = night;

    this.cdRef.detectChanges();
  }

  unhighlightCell() {
    this.highlightedDate = null;
    this.highlightedNight = null;

    this.cdRef.detectChanges();
  }

  isRowHighlighted(night: number): boolean {
    return this.highlightedNight === night;
  }

  isColHighlighted(date: string): boolean {
    return this.highlightedDate === date;
  }

  isCellHighlighted(date: string, nights: number): boolean {
    return this.highlightedDate === date && this.highlightedNight === nights;
  }

  showTours(nights: number, date: string): void {
    this.selectedNights.set(nights);
    this.selectedDate.set(date);

    this.updateToursInCell(nights, date);
    const initSearchRequest: InitSearchRequest = {
      id: WebsocketToursSearchService.generateId(),
      method: InitSearchRequestMethod.search,
      params: {
        params: {
          adults: this.viewedTour().tourists.adults,
          childrenAges: this.viewedTour().tourists.childAges.map(age => Number(age)),
          splitRooms: false,
          combined: 0,
          countryId: this.viewedTour().country.id,
          dateFrom: date,
          dateTo: date,
          departCityId: this.viewedTour().departCity.id,
          hotels: [this.viewedTour().hotel.id],
          nightsFrom: nights,
          nightsTo: nights,
          notGDS: this.initCreateRequest.hasOwnProperty('notGDS') ? this.initCreateRequest.notGDS : true,
          onlyHotels: false,
        },
        options: {
          type: InitSearchRequestType.calc,
          groupResults: InitSearchRequestOptionsGroupResult.noGroup,
          allowParallel: true,
        },
      },
    };

    this.showSearchToursLoader.set(false);
    this.showSearchToursLoader.set(true);
    this.searchToursService
      .searchTours(initSearchRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          if (result.hasTours) {
            const newTours = [];
            result.tours.forEach(tour => {
              const calendarTour = this.calendarTours.find(
                t =>
                  t.date === tour.date &&
                  t.nights === tour.nights &&
                  t.nightsOnWay === tour.nightsOnWay &&
                  t.operator.id === tour.operator.id &&
                  t.isGDS === tour.isGDS &&
                  t.isCombined === tour.isCombined &&
                  t.meal.id === tour.meal.id &&
                  JSON.stringify(tour.freights.airlines) === JSON.stringify(tour.freights.airlines),
              );

              if (!calendarTour || calendarTour.brandPrice.value > tour.brandPrice.value) {
                newTours.push(tour);
              }
            });

            if (newTours.length > 0) {
              this.calendarTours = this.calendarTours.concat(newTours);
            }
          }

          if (result.searchIsDone) {
            this.showSearchToursLoader.set(false);
            this.updateToursInCell(nights, date);
          }
        },
        error: error => {
          this.showSearchToursLoader.set(false);
        },
      });
  }

  selectTour(tour: SearchResultsResponseTour) {
    this.viewedTour.set(tour);
  }

  closeModal() {
    this.closed.emit();
  }

  updateCalendar() {
    const request = this.getCreateApiRequest();
    this.requestSub.next(request);
  }

  private getCreateApiRequest(): ManagerSearchTourCalendarRequest {
    const filters = this.filterForm.value;

    return {
      tourId: this.tourId,
      mealIds: filters.mealIds,
      operatorIds: filters.operatorIds,
      airlineIds: filters.airlineIds,
      dateFromShift: this.calendarShiftValue,
      notGDS: !!this.notGDS,
    };
  }

  private createCalendar(calendar: ManagerTourCalendar, calcFilters: boolean) {
    const items = calendar.items;

    const nightsFrom = calendar.itemsNightsFrom;
    const nightsTo = calendar.itemsNightsTo;
    const dateFrom = new Date(calendar.itemsDateFrom);
    const dateTo = new Date(calendar.itemsDateTo);

    this.dates = this.createDateRangeArray(dateFrom, dateTo);
    this.nights = Array.from({ length: nightsTo - nightsFrom + 1 }, (v, i) => i + nightsFrom);

    if (calcFilters) {
      this.calcFilters(calendar);
    }

    const tableData: TableCell = {};
    this.nights.forEach(night => {
      tableData[night] = {};
      this.dates.forEach(date => {
        tableData[night][date] = null;
      });
    });

    items.forEach(tour => {
      if (tableData[tour.nights]) {
        tableData[tour.nights][tour.date] = tour;
      }
    });

    this.tableData = tableData;
  }

  private populateFilters() {
    if (this.initCreateRequest) {
      if (this.initCreateRequest?.mealIds?.length) {
        this.filterForm.get('mealIds').setValue(this.initCreateRequest.mealIds);
      }
      if (this.initCreateRequest?.airlineIds?.length) {
        this.filterForm.get('airlineIds').setValue(this.initCreateRequest.airlineIds);
      }
      if (this.initCreateRequest?.operatorIds?.length) {
        this.filterForm.get('operatorIds').setValue(this.initCreateRequest.operatorIds);
      }
    }
  }

  private calcFilters(calendar: ManagerTourCalendar): void {
    this.mealVariants = calendar.mealsVariants.map(v => {
      return {
        id: v.id,
        name: v.name,
        selected: false,
      };
    });
    this.operatorVariants = calendar.operatorVariants.map(v => {
      return {
        id: v.id,
        name: v.name,
        selected: false,
      };
    });
    this.airlineVariants = calendar.airlinesVariants.map(v => {
      return {
        id: v.id,
        name: v.name,
        selected: false,
      };
    });
  }

  private createDateRangeArray(dateFrom: Date, dateTo: Date): string[] {
    const dateArray: string[] = [];
    const currentDate = new Date(dateFrom);

    while (currentDate <= dateTo) {
      dateArray.push(new Date(currentDate).toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  }

  private updateToursInCell(nights: number, date: string): void {
    const toursInCell = this.calendarTours.filter(tour => tour.date === date && tour.nights === nights);
    toursInCell.sort((a, b) => a.brandPrice.value - b.brandPrice.value);

    this.toursInCell.set(toursInCell);
  }
}
